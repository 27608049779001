<!-- 事务所 小程序的内嵌入口 -->
<template>
  <div class="main-wrapper" v-loading="!loaded" element-loading-text="正在验证授权" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <router-view v-if="isAuthored" />
  </div>
</template>

<script>
import { getPreInspectResult } from "@/api/oem";
import {  authAESEncrypt, authAESDecrypt, bThirdFetchInfo, getCheckExistence } from "@/api/api";

export default {
  name: "oemGuardV2",
  data() {
    return {
      loaded: false,
      isAuthored: false,
      loginMsg: "",
    };
  },
  components: {},
  async mounted() {
    //
    // 打开的链接 带key的链接 去解密出参数
    if (this.$route.query.uuid || this.$route.query.key) {
      let key
      let res
      if (this.$route.query.key) {
        key = decodeURIComponent(this.$route.query.key);
        let params = {
          encryptContent: key,
        };
        res = await authAESDecrypt(params);
        await this.$store.commit("oem/setZq", res.data.zq);

      } else {

        key = this.$route.query.uuid;
        let params = {
          programId: key
        }
        res = await bThirdFetchInfo(params)
        await this.$store.commit("oem/setZq", res.data.zqId);

        if (this.$route.query.from == 'lvat') {
          // const res2 = await getCheckExistence(params)

        } else {
          const res2 = await getCheckExistence(params)

        }


      }



      let data = {
        preInspectId: res.data.programId,
        companyTaxNo: res.data.taxNo,
        companyName: res.data.companyName,
        companyNo: res.data.companyNo,
        channelCode: res.data.channelCode,
      };
      await this.$store.commit("oem/setAuth", data);
      this.loaded = true;
      this.isAuthored = true;
      return;
    }

    // 
    // 版本3小程序端  版本2事务所  税务师暂无分享填写链接
    // 参数传入加密为分享的链接
    let data = null;
    if (this.$route.query.version == 3) {
      data = {
        companyName: this.$route.query.companyName,
        companyNo: this.$route.query.companyNo,
        companyTaxNo: this.$route.query.companyTaxNo,
        hydm: this.$route.query.hydm,
        preInspectId: this.$route.query.preInspectId,
      };
    } else if (this.$route.query.version == 2) {
      data = JSON.parse(this.$route.query.info);
      // 加密
      let params = {
        companyName: data.companyName,
        programId: data.preInspectId,
        taxNo: data.companyTaxNo,
        zq: this.$route.params.zq,
        channelCode: "shiwusuo",
        companyNo: data.companyNo,
      };
      let res = await authAESEncrypt(params);
      data.loginMsg = res.data;
      const froms = this.$route.query.from ? this.$route.query.from : 'shiwusuo'
      data.loginUrl =
        process.env.VUE_APP_PDF_ROOT_FETCH +
        "oemV3/auth" +
        "?key=" +
        encodeURIComponent(res.data) + '&from=' + froms + '&info=' + this.$route.query.info + '&token=' + this.$route.query.token;
    }
    await this.$store.commit("oem/setAuth", data);
    await this.$store.commit("oem/setZq", this.$route.params.zq);

    this.loaded = true;
    this.isAuthored = true;
  },
};
</script>
<style lang="stylus" scoped>
.main-wrapper {
//   min-height 100vh
}
</style>
